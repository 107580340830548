import React from 'react';
import { graphql } from "gatsby"
import Styled from '@emotion/styled';
import Layout from '../components/layout';
import GridItem from '../components/gridItem';
import GridSlideShow from '../components/gridSlideShow';
const OfficePage = ({ data }) => {



  const offices = [
    {
      id: 'dunedin',
      title: "Dunedin",
      address: "2 Dowling Street (Commerce Building)<br/>City Centre",
      phone: "+6434701122",
      phone_label: "03 470 1122",
      email: "contact@dpmgroup.nz",
      gallery: data.dunedin.edges.map(e => e.node),
      featured_image: data.dunedin.edges.map(e => e.node)[0]
    },
    {
      id: 'centralOtago',
      title: "Queenstown",
      address: "44 Beach Street<br/>Town Centre",
      phone: "+6434701122",
      phone_label: "03 470 1122",
      email: "contact@dpmgroup.nz",
      gallery: data.queenstown.edges.map(e => e.node),
      featured_image: data.queenstown.edges.map(e => e.node)[0]
    },
  ]
  console.log({ offices })
  return (

    <Layout isHome={false}>
      <div className="hero-body" style={{ padding: '0.75rem 1.5rem' }}>
        <StyledContainer className="container">
          <div className="columns is-multiline">
            {
              offices.map((o, i) => (
                <GridItem p={o} i={i} key={`office-${i}`} isOffice={true} />
              ))
            }
          </div>
        </StyledContainer>
      </div>
    </Layout >
  );
};

export default OfficePage;


export const query = graphql`
  query{
    dunedin: allFile(
      filter: {relativePath: {regex: "/office/dunedin\/0*/"}}
      sort: {fields: relativePath}
      ) {
      edges {
        node {
          childImageSharp {
            fluid(maxWidth: 1600, maxHeight: 1070, quality: 70) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        } 
      }
    }
    queenstown: allFile(
      filter: {relativePath: {regex: "/office/queenstown\/0*/"}}
      sort: {fields: relativePath}
      ) {
      edges {
        node {
          childImageSharp {
            fluid(maxWidth: 1600, maxHeight: 1070, quality: 70) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        } 
      }
    }
  }`


const StyledContainer = Styled('div')`
  margin-top: 8em;
  @media screen and (max-width: 520px) {
    margin-top: 6em;
  }
}`

